export const getProductNotifications = async (axios) => {
  try {
    return (await axios.get('/api/customer/notification/product/')).data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const markProductTouched = async (axios, product) => {
  try {
    return (
      await axios.get(
        `/api/customer/notification/product/${product.product_id}/mark_touched/`
      )
    ).data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const markProductHidden = async (axios, product) => {
  try {
    return (
      await axios.get(
        `/api/customer/notification/product/${product.product_id}/mark_hidden/`
      )
    ).data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const markAllProductsViewed = async (axios) => {
  try {
    return (
      await axios.get(`/api/customer/notification/product/mark_all_viewed/`)
    ).data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const markAllProductsTouched = async (axios) => {
  try {
    return (
      await axios.get(`/api/customer/notification/product/mark_all_touched/`)
    ).data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const markAlertBellTouched = async (axios) => {
  try {
    return (
      await axios.get(
        `/api/customer/notification/product/mark_alert_bell_touched/`
      )
    ).data
  } catch (e) {
    console.error(e)
    throw e
  }
}
