<template>
  <div
    v-click-outside="clickOutside"
    :class="['notification-results', notificationsOpen ? 'open' : '']"
  >
    <button v-if="mobile" class="close" @click="closeMobileNotification()">
      Close
    </button>
    <div role="region" aria-describedby="search-product">
      <h2 v-if="productNotifications.length > 0" id="search-product">
        Now Back in Stock
        <div style="display: none;" role="alert">
          {{ productNotifications.length }} notification{{
            productNotifications.length !== 1 ? 's' : ''
          }}
        </div>
        <span v-if="newProductNotifications.length > 0"
          >({{ newProductNotifications.length }})</span
        >
      </h2>
      <ul v-if="productNotifications.length > 0" class="products">
        <li
          v-for="notification in productNotifications"
          :key="notification.product.product_id"
          :data-product-id="notification.product.product_id"
        >
          <nuxt-link
            v-if="getPrice(notification.product)"
            :to="notification.product.product_url"
            :event="addingProduct ? '' : 'click'"
            @click.native="productLinkClicked(notification)"
          >
            <span v-if="!notification.touched_at" class="badge dot">
              <span class="sr-only">New/unread item</span>
            </span>
            <span
              class="remove"
              @click.stop.prevent="removeNotification(notification)"
            >
              <span class="sr-only">Remove Item</span>
            </span>
            <span class="image">
              <picture class="lazyLoad isLoaded">
                <source
                  v-lazy-load :data-srcset="notification.product.optimized_image_url"
                  type="image/webp"
                />
                <source
                  v-lazy-load :data-srcset="notification.product.image_url"
                  type="image/jpeg"
                />
                <img
                  v-lazy-load :data-src="notification.product.image_url"
                  alt="Bottle Image"
                  class="lazyLoad isLoaded"
                  height="220"
                />
              </picture>
            </span>
            <span class="name">{{ notification.product.name }}</span>
            <span class="winery">{{ notification.product.winery }}</span>
            <span class="price"
              ><Price :amt="getPrice(notification.product)"
            /></span>
            <span
              v-if="!productInCart(notification.product)"
              class="addtocart"
              @click.stop.prevent="addToCart(notification)"
              >Add to cart</span
            >
            <span v-else class="incart">Added to cart</span>
          </nuxt-link>
          <div v-if="notification.product.adding" class="loading">
            <div
              class="backdrop"
              style="
                position: absolute;
                background: rgba(255, 255, 255, 0.45);
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
              "
            ></div>
            <spinner></spinner>
          </div>
        </li>
      </ul>
      <div v-else class="message">
        <div style="display: none;" role="alert">
          {{ portfolioLinkText }}
        </div>
        <nuxt-link
          to="/cellar/wines/"
          class="view-all"
          @click.native="closeMobileNotification"
          >{{ portfolioLinkText }}</nuxt-link
        >
      </div>
    </div>
    <nuxt-link
      v-if="productNotifications.length > 0"
      to="/cellar/wines/"
      class="view-all"
      @click.native="closeMobileNotification"
      ><template v-if="mobile"
        ><span>{{ portfolioLinkText }}</span></template
      >
      <template v-else>{{ portfolioLinkText }}</template>
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner from '~/../common/components/Loading/Spinner'
import Price from '~/../common/components/Price.vue'
import {
  markProductTouched,
  markProductHidden,
} from '~/services/notificationServices'

export default {
  name: 'AlertNotifications',
  components: { Spinner, Price },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      addingProduct: false,
      portfolioLinkText:
        'Be the first to know when past favorites are back in stock',
    }
  },
  computed: {
    ...mapGetters([
      'cart',
      'notifications',
      'productNotifications',
      'notificationsOpen',
      'newProductNotifications',
      'mobileNotificationsOpen',
    ]),
  },
  methods: {
    removeNotification(notification) {
      this.$store.dispatch('setNotificationHidden', notification)
      markProductHidden(this.$axios, notification.product)
    },
    getPrice(product) {
      const sr = product.active_stockrecords.find(
        (x) => x.partner === this.$auth.user.partner_id
      )
      return sr ? Number(sr.price_excl_tax) : undefined
    },
    productInCart(product) {
      if (this.cart.lines) {
        return this.cart.lines.find((x) => x.product_id === product.product_id)
      }

      return false
    },
    async addToCart(notification) {
      const product = notification.product
      if (this.addingProduct) {
        return
      }
      this.addingProduct = true
      product.adding = true
      this.markTouched(notification)
      this.$forceUpdate()
      await this.$store.dispatch('addToCart', {
        quantity: 1,
        product_id: product.product_id,
        disableBackdrop: true,
      })
      this.addingProduct = false
      product.adding = false
      this.$forceUpdate()
    },
    clickOutside(event) {
      if (!this.notificationsOpen) {
        return
      }
      this.$store.dispatch('setNotificationsOpen', false)
    },
    productLinkClicked(notification) {
      this.closeMobileNotification()
      this.markTouched(notification)
    },
    markTouched(notification) {
      if (!notification.touched_at) {
        this.$store.dispatch('setNotificationTouched', {
          notification,
          touched_at: true,
        })
        markProductTouched(this.$axios, notification.product)
      }
    },
    closeMobileNotification(event) {
      this.$store.dispatch('setShowMobileMenu', false)
      this.$store.dispatch('setMobileNotificationsOpen', false)
    },
  },
}
</script>
