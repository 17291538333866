export const getCheckoutAddressString = (
  address,
  withName = false,
  withPostCode = true
) => {
  const _address = `${address.line3 ? address.line3 + ', ' : ''}${
    address.line1
  },${address.line2 ? ' ' + address.line2 + ', ' : ''}${
    address.line4 ? ' ' + address.line4 + ', ' : ''
  }${!address.line4 && address.city ? ' ' + address.city + ', ' : ''}${
    address.state_name ? address.state_name : address.state
  }${withPostCode ? ' ' + address.postcode : ''}`
  if (withName) {
    // If this is a pickup location returned from API
    if (address.location_id) {
      return `${address.company}, ${_address}`
    } else if (address.first_name && address.last_name) {
      return `${address.first_name} ${address.last_name}, ${_address}`
    }
  }
  return _address
}

export const updateAddressVerificationState = (
  $store,
  showAddressModal,
  suggestedAddress,
  enteredAddress
) => {
  $store.dispatch('setAddressVerificationState', {
    modal: {
      show: showAddressModal,
      selectedOption: 'verifySuggestedAddress',
    },
    suggestedAddress,
    enteredAddress,
  })
}

export const formatPrice = (amt) =>
  amt === Math.floor(amt) ? amt : typeof amt === 'number' ? amt.toFixed(2) : amt
