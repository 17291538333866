<template>
  <span>${{ formatPrice(amt) }}</span>
</template>

<script>
import { formatPrice } from '~/../common/utils/checkout'

export default {
  name: 'PriceComponent',
  props: {
    amt: {
      type: Number,
      required: true,
    },
  },
  methods: { formatPrice },
}
</script>
