export const arrayToOptions = function (data) {
  const options = {}
  data.forEach((item) => {
    options[item.value] = item.display
  })

  return options
}

export const getHeaderHeight = () => {
  const header = document.getElementsByClassName('site-header-container')[0]
  if (process.client) {
    return header ? header.clientHeight : 0
  } else {
    return 130
  }
}
